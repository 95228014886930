import React, { useState, useEffect } from 'react';

const ProductDisplay = () => {
  const [name, setName] = useState('');
  const [takenMessage, setTakenMessage] = useState('');
  const handleNameChange = ({ target }) => {
    setTakenMessage('');
    setName(target.value)
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    fetch('https://zhopn.com/api/pay/create-checkout-session?type=newStore', {
      method: 'POST',
      body: JSON.stringify({ name }),
    })
    .then((response) => {
      if (response.status === 409) {
        throw 'Name already taken';
      }

      if (!response.ok) {
        throw 'Unknown error';
      }

      return response;
    })
    .then(response => response.json())
    .then(({ url }) => (window.location.href = url))
    .catch((message) => setTakenMessage(message));
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1>Set up your store for £2.99 a month</h1>
      <h2>Choose your name</h2>
      <input type="text" onChange={handleNameChange} value={name} />
      {Boolean(takenMessage) && <p>{takenMessage}</p>}
      <button type="sumbit">Create subscription</button>
    </form>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default () => {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  if (!success && message === '') {
    return <ProductDisplay />;
  } else if (success) {
    return 'Success!';
  } else {
    return <Message message={message} />;
  }
};
