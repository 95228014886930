import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default () => {
    const [stores, setStores] = useState([]);

    useEffect(() => {
        fetch('https://zhopn.com/api/stores')
            .then(response => response.json())
            .then(setStores)
            .catch(() => {});
    }, []);

    return (
        <>
            Home
            <ul>
                {stores.map(({ name, key }) => (<li key={key}><Link to={`stores/${key}`}>{name}</Link></li>))}
            </ul>
        </>
    )
};