import { useEffect } from 'react';

export default ({ history }) => {
    useEffect(() => {
        fetch('https://zhopn.com/logout')
            .finally(() => history.push('/logged-out'));
    }, []);

    return (
        <>Logging out</>
    );
};