import { useEffect, useState } from "react";

const handleUnauthenticated = () => process.env.REACT_APP_ENVIRONMENT !== 'development' && window.location.reload();

const Product = ({ id, name, handleUpdate }) => {
    const [newName, setNewName] = useState(name);
    const [previousNewName, setPreviousNewName] = useState(name);
    const handleChange = (event) => setNewName(event.target.value);

    useEffect(() => {
        if (name !== previousNewName) {
            setPreviousNewName(name);
            setNewName(name);
        }
    }, [name, previousNewName, setPreviousNewName, setNewName]);

    return (
        <>
            <input type="text" onChange={handleChange} value={newName} /><button onClick={() => handleUpdate({ id, name: newName })}>Update</button>
        </>
    );
};

export default () => {
    const [products, setProducts] = useState([]);
    const [newName, setNewName] = useState('');
    const handleChange = (event) => setNewName(event.target.value);
    const handleAdd = () => {
        fetch('https://zhopn.com/api/products', {
            method: 'POST',
            body: JSON.stringify({ name: newName }),
        })
            .then(response => response.json())
            .then((products) => {
                setProducts(products);
                setNewName('');
            });
    };
    const handleDelete = (id) => {
        fetch(`https://zhopn.com/api/products?id=${id}`, {
            method: 'DELETE',
        })
            .then(response => response.json())
            .then(setProducts);
    };
    const handleUpdate = ({ id, name }) => {
        fetch(`https://zhopn.com/api/products?id=${id}`, {
            method: 'PUT',
            body: JSON.stringify({ name }),
        })
            .then(response => response.json())
            .then(setProducts);
    };

    useEffect(() => {
        fetch('https://zhopn.com/session')
            .then(({ ok }) => !ok && handleUnauthenticated())
            .then(() => {
                fetch('https://zhopn.com/api/products')
                    .then(response => response.json())
                    .then(setProducts);
            })
            .catch(() => handleUnauthenticated());
    }, []);

    return (
        <>
            <h1>Dashboard</h1>
            <ul>
                {products.map(({ id, name }) => (<li key={id}><Product {...{ id, name }} handleUpdate={handleUpdate} /><button onClick={() => handleDelete(id)}>Delete</button></li>))}
                <li><input type="text" onChange={handleChange} value={newName} /><button onClick={handleAdd}>Add</button></li>
            </ul>
        </>
    )
};