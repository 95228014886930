import { useEffect } from 'react';

export default () => {
    useEffect(() => {
        if (window.location.href.includes('hello=true')) {
            window.location.href = `${window.location.href.replace('?hello=true', '')}`
        }
    }, []);
    return (
        <>Logged out</>
    );
};