import {
  BrowserRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Home from './components/Home';
import LogOut from './components/LogOut';
import LoggedOut from './components/LoggedOut';
import Dashboard from './components/Dashboard';
import Store from './components/Store';
import SellYourProducts from './components/SellYourProducts';
import NotFound from './components/NotFound';

import useClearEmptyUrlHash from './hooks/useClearEmptyUrlHash';

const stripePromise = loadStripe('pk_test_51Hbm3jKjHSgkaxZkidFnYfAWijyA66z6jVMYqUXcBXQb4zgUciw48kPxEnS0nt0MIRA4vDj2uddgu9AbW1oyDU1M00zjCamII5');

export default () => {
  useClearEmptyUrlHash();

  return (
    <BrowserRouter>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/dashboard">Dashboard</Link></li>
        <li><Link to="/sell-your-products">Sell your products</Link></li>
        <li><Link to="/log-out">Log out</Link></li>
      </ul>
      <Elements stripe={stripePromise}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/stores/:key" component={Store} />
          <Route exact path="/sell-your-products" component={SellYourProducts} />
          <Route exact path={['/log-out', '/logout']} component={LogOut} />
          <Route exact path="/logged-out" component={LoggedOut} />
          <Route component={NotFound} />
        </Switch>
      </Elements>
    </BrowserRouter>
  );
};
