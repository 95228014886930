import { useEffect, useState } from "react";

export default ({ match }) => {
    const [store, setStore] = useState({});
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState([]);
    const key = match.params.key;
    const search = encodeURI(JSON.stringify({ key }));
    const handleAddToCart = ({ id, name, decrement = false }) => {
        const existingCartProduct = cart.find(({ productKey }) => productKey === id);

        if (existingCartProduct) {
            if (existingCartProduct.quantity === 1 && decrement) {
                fetch(`https://zhopn.com/api/carts/${key}?id=${existingCartProduct.id}`, {
                    method: "DELETE",
                })
                    .then(response => response.json())
                    .then(setCart);
            } else {
                fetch(`https://zhopn.com/api/carts/${key}?id=${existingCartProduct.id}`, {
                    method: "PUT",
                    body: JSON.stringify({
                        quantity: decrement ? existingCartProduct.quantity - 1 : existingCartProduct.quantity + 1
                    }),
                })
                    .then(response => response.json())
                    .then(setCart);
            }
        } else {
            fetch(`https://zhopn.com/api/carts/${key}`, {
                method: "POST",
                body: JSON.stringify({ productKey: id, name, quantity: 1 }),
            })
                .then(response => response.json())
                .then(setCart);
        }
    };
       
    const handleOrder = () => {
        fetch(`https://zhopn.com/api/orders/${key}`, {
            method: "POST",
            body: JSON.stringify({ cart }),
        })
            .then(response => response.json())
            .then((orders) => {
                const { id } = orders[orders.length - 1];

                fetch(`https://zhopn.com/api/carts/${key}`)
                    .then(response => response.json())
                    .then(setCart);

                alert(`Ordered successfully, order number: ${key}-${id}`);
            });
    };

    useEffect(() => {
        fetch(`https://zhopn.com/api/stores?search=${search}`)
            .then(response => response.json())
            .then((stores) => setStore(stores[0]));

        fetch(`https://zhopn.com/api/products/${key}`)
            .then(response => response.json())
            .then(setProducts);
        
        fetch(`https://zhopn.com/api/carts/${key}`)
            .then(response => response.json())
            .then(setCart);
    }, []);

    return (
        <>
            {store.name}
            <ul>
                {products.map(({ id, name }) => (<li key={id}>{name} <button onClick={() => handleAddToCart({ id, name })}>Add to cart</button></li>))}
            </ul>
            Cart
            <ul>
                {cart.map(({ id, name, quantity, productKey }) => (<li key={id}>{name} x <button onClick={() => handleAddToCart({ id: productKey, name, decrement: true })}>-</button>{quantity}<button onClick={() => handleAddToCart({ id: productKey, name })}>+</button></li>))}
            </ul>
            <button onClick={handleOrder}>Order</button>
        </>
    )
};